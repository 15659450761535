import React from 'react'
import { graphql } from 'gatsby'
import Reactmarkdown from 'react-markdown'
import { useIntl } from 'gatsby-plugin-intl'

import Alert from '../components/Alert/Alert'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'react-grid-system'
import Layout from '../components/Layouts/layout'
import { FaCalendar } from 'react-icons/fa'
import moment from 'moment'
import componentStyles from './article.module.scss'

const ArticleTemplate = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout
      header={data.markdownRemark.frontmatter.title}
      breadcrumb={{
        text: 'News Releases',
        path: '/news-and-events/news-releases/'
      }}
    >
      <Container>
        {intl.locale === 'fr' && (
          <Row>
            <Col>
              <Alert header='Attention'>
                <p lang='fr'>
                  Pour les services en français, veuillez contacter le Service
                  des licences et à la clientèle de l’Office de réglementation
                  de la construction des logements au 416-487-HCRA (4272) ou{' '}
                  <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
                </p>
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={4} md={6} style={{ marginBottom: `2rem` }}>
            <div style={{ marginBottom: `2rem` }}>
              <div className={componentStyles.dateContainer}>
                <FaCalendar className={componentStyles.icon} />
                <span className='bold uppercase'>
                  {moment(data.markdownRemark.frontmatter.date).format(
                    'MMM Do, YYYY'
                  )}
                </span>
              </div>
            </div>
            <Img
              fluid={
                data.markdownRemark.frontmatter.image.childImageSharp.fluid
              }
            />
          </Col>

          <Col lg={8} md={6}>
            <h2>{data.markdownRemark.frontmatter.subtitle}</h2>
            <Reactmarkdown source={data.markdownRemark.rawMarkdownBody} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      rawMarkdownBody
      frontmatter {
        title
        subtitle
        date
        image {
          absolutePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`
